 
import './App.css';
 
import { BrowserRouter,Route,Routes } from 'react-router-dom'; 
import { Helmet } from 'react-helmet';
import Mainpage from './Mainpage.jsx';
import ProductPage from './Components/Productpage/ProductPage.jsx';
import TestimonialsSubPage from './Components/TestimonialsSubPage/TestimonialsSubPage.jsx'
import ProductPagewebsites from './Components/Productpage/ProductPagewebsites.jsx';


function App() {
  return (


    
      <div className="App">
       
        <Helmet>
           <title>Usama Digital</title>
        </Helmet>
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<Mainpage/>}></Route>
            <Route path='/portfolio' element={<ProductPage/>}></Route>
            <Route path='/testimonials' element={<TestimonialsSubPage/>}></Route>
            <Route path='/projectmanagementprojects' element={<ProductPage/>}></Route>
            <Route path='/websiteprojects' element={<ProductPagewebsites/>}></Route>
              
          </Routes> 
        </BrowserRouter>
      </div>
 
   
  );
}


export default App;
