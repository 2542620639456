import React, { useState, useEffect } from 'react';
import './education.css';

const Education = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const windowHeight = window.innerHeight;
      const educationElement = document.getElementById('education');
      const educationElementTop = educationElement.getBoundingClientRect().top;

      if (educationElementTop < windowHeight * 0.75) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={`app__education section__margin ${isVisible ? 'show' : ''}`} id='education'>
      <h1 className="education-heading">Education</h1>
      <div className="education-container">
        <div className="education-item">
          <h2 className="year">2018</h2>
          <div className="education-details">
            <h3 className="degree">Bachelor of Software Engineering</h3>
            <p className="university">University of Agriculture, Faisalabad (UAF)</p>
          </div>
        </div>
        <div className="education-item">
          <h2 className="year">2024</h2>
          <div className="education-details">
            <h3 className="degree">Master in Strategic Digital Marketing</h3>
            <p className="university">Arden University</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Education;
