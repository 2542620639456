import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ImageCard from './ImageCard';
import projectsData from '../../projectsData';
import './projectcarousel.css';
import { Link } from 'react-router-dom';

const ProjectCarousel = () => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Shows 3 cards at a time on desktops and iPads
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2, // Maintain 3 cards at a time on iPads
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1, // Shows 1 card at a time on mobile devices
          slidesToScroll: 1,
          initialSlide: 1,
          dots: false,
        }
      }
    ]
  };

  return (
    <div className="project-carousel-container">
      <Slider {...settings} className="slider">
        {projectsData.map((project) => (
         <div key={project.id}>
            {/* <a href={project.url} target="_blank" rel="noopener noreferrer"> */}
              <div className="slide-content">
                <ImageCard
                  imageUrl={project.image}
                  title={project.name}
                  description={project.description}
                />
              </div>
            {/* </a> */}
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ProjectCarousel;
