import React from 'react'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ImageCard from '../../Components/TabComponents/Tab1/ImageCard';
import Reviews from '../Reviews';
import '../../Components/TabComponents/Tab1/projectcarousel.css';
import { Link } from 'react-router-dom';
import './testimonials.css'

const Testimonials = () => {

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Shows 3 cards at a time on desktops and iPads
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2, // Maintain 3 cards at a time on iPads
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1, // Shows 1 card at a time on mobile devices
          slidesToScroll: 1,
          initialSlide: 1,
          dots: false,
        }
      }
    ]
  };
  return (
    <div id='Testimonials' style={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'} }>
      <h1 className='headtext__cormorant' style={{marginBottom:"2rem"}}>Testimonials </h1>
      <div className="project-carousel-container">
      <Slider {...settings} className="slider">
        {Reviews.map((project) => (
          <div key={project.id}>
           
              <div className="slide-content">
                <ImageCard
                  imageUrl={project.image}
                  title={project.name}
                  description={project.description}
                  customClassName="testimonial-image-card" 
                />
              </div>
             
          </div>
        ))}
      </Slider>
      
      </div>
      <Link to='/testimonials'>  <button className='explore_more-button'  >View All</button></Link>
    </div>
    
  );
};


export default Testimonials