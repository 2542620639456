// projectsData.js
import Review1 from './Reviews/Review (1).jpg'
import Review2 from './Reviews/Review (2).jpg'
import Review3 from './Reviews/Review (3).jpg'
import Review4 from './Reviews/Review (4).jpg'
import Review5 from './Reviews/Review (5).jpg'
import Review6 from './Reviews/Review (6).jpg'
import Review7 from './Reviews/Review (7).jpg'
import Review8 from './Reviews/Review (8).jpg'
import Review9 from './Reviews/Review (9).jpg'
import Review10 from './Reviews/Review (10).jpg'
import Review11 from './Reviews/Review (11).jpg'
import Review12 from './Reviews/Review (12).jpg'
import Review13 from './Reviews/Review (13).jpg'
import Review14 from './Reviews/Review (14).jpg'
import Review15 from './Reviews/Review (15).jpg'
import Review16 from './Reviews/Review (16).jpg'
import Review17 from './Reviews/Review (17).jpg'
import Review18 from './Reviews/Review (18).jpg'
import Review19 from './Reviews/Review (19).jpg'
import Review20 from './Reviews/Review (20).jpg'
import Review21 from './Reviews/Review (21).jpg'
import Review22 from './Reviews/Review (22).jpg'
import Review23 from './Reviews/Review (23).jpg'
import Review24 from './Reviews/Review (24).jpg'
import Review25 from './Reviews/Review (25).jpg'
import Review26 from './Reviews/Review (26).jpg'
import Review27 from './Reviews/Review (27).jpg'

 
const Reviews = [
    {
      id: 1,
      name: " ", 
      image: Review1,
      url:" "
    },
    {
      id: 2,
      name: " ", 
      image: Review2,
      url:" "
    },
    {
      id: 3,
      name: " ", 
      image: Review3,
      url:" "
    },
    {
      id: 4,
      name: " ", 
      image: Review4,
      url:" "
    },
    {
      id: 5,
      name: " ", 
      image: Review5,
      url:" "
    },
    {
      id: 6,
      name: " ", 
      image: Review6,
      url:" "
    },
    {
      id: 7,
      name: " ", 
      image: Review7,
      url:" "
    },
    {
      id: 8,
      name: " ", 
      image: Review8,
      url:" "
    },
    {
      id: 9,
      name: " ", 
      image: Review9,
      url:" "
    },
    {
      id:10,
      name: " ", 
      image: Review10,
      url:" "
    },
    {
      id: 11,
      name: " ", 
      image: Review11,
      url:" "
    },
    {
      id: 12,
      name: " ", 
      image: Review13,
      url:" "
    },
    {
      id: 14,
      name: " ", 
      image: Review14,
      url:" "
    },
    {
      id: 15,
      name: " ", 
      image: Review15,
      url:" "
    },
    {
      id: 16,
      name: " ", 
      image: Review16,
      url:" "
    },
    {
      id: 17,
      name: " ", 
      image: Review17,
      url:" "
    },
    {
      id: 18,
      name: " ", 
      image: Review18,
      url:" "
    },
    {
      id: 19,
      name: " ", 
      image: Review19,
      url:" "
    },
    {
      id: 20,
      name: " ", 
      image: Review20,
      url:" "
    },
    {
      id: 21,
      name: " ", 
      image: Review21,
      url:" "
    },
    {
      id: 22,
      name: " ", 
      image: Review22,
      url:" "
    },
    {
      id: 23,
      name: " ", 
      image: Review23,
      url:" "
    },
    {
      id: 24,
      name: " ", 
      image: Review24,
      url:" "
    },
    {
      id: 25,
      name: " ", 
      image: Review25,
      url:" "
    },
    {
      id: 26,
      name: " ", 
      image: Review26,
      url:" "
    },
    {
      id: 27,
      name: " ", 
      image: Review27,
      url:" "
    },
     
    // Add more projects as needed
  ];
  
   
  
  export default Reviews;
  