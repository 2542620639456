import React, { useState, useEffect } from 'react';
import './personalachievement.css';

import { Link } from 'react-router-dom';

const PersonalAchievement = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const windowHeight = window.innerHeight;
      const personalAchievementsElement = document.querySelector('.personal-achievements-container');
      const personalAchievementsElementTop = personalAchievementsElement.getBoundingClientRect().top;

      if (personalAchievementsElementTop < windowHeight * 0.75) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={`personal-achievements-container section__margin ${isVisible ? 'show' : ''}`}>
      <h1 className="heading">Personal Accomplishment</h1>
      <div className="personal-achievements">
      
          <div className="achievement-box" >
          <h2 className="title">100+</h2>   
              <p className="description">Successful 100+ Projects managed from all over the world.</p>
            </div>
     
         
         
        
        <div className="achievement-box">
          <h2 className="title">10+</h2>
          <p className="description">Successful 10+ React.js Single Page Sites   </p>
        </div>
        <div className="achievement-box">
          <h2 className="title">2000+</h2>
          <p className="description"> Organic 2000+ Linkedin Community and still growing</p>
        </div>
        <div className="achievement-box">
          <h2 className="title">5+</h2>
          <p className="description">Managed Marketing Strategy Compaigns for 5+ Companies </p>
        </div>
      </div>
    </div>
  );
};

export default PersonalAchievement;
