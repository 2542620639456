// projectsData.js
import pansari from './Assets/social Media Marketing (2).png'
import Step from './Assets/social Media Marketing (1).png'
import JpgCustom from './Assets/social Media Marketing (3).png'
import Italiano from './Assets/Italiano.png'

 
const SocialMedia = [
    {
      id: 1,
      name: "Italiano", 
      image: Italiano,
      url:"https://www.instagram.com/italiano.clothing.store/"
    },
    {
      id: 2,
      name: "StepStudio", 
      image: Step,
      url:"https://www.instagram.com/thestepstudio/"
    },
    {
      id: 3,
      name: "Pansari",      
      image: pansari,
      url:"https://www.instagram.com/pansarionline/"
    },
    {
      id: 4,
      name: "JPGCustomTshirtPrinting",     
      image: JpgCustom,
      url:"https://www.instagram.com/jpgcustomtshirtprinting/"
    }
    // Add more projects as needed
  ];
  
   
  
  export default SocialMedia;
  