import React from 'react';

const GradientCircle = ({ radius, percentage, textColor, gradient, title }) => {
  const circumference = 2 * Math.PI * radius;
  const strokeDasharray = `${circumference} ${circumference}`;
  const strokeDashoffset = circumference - (percentage / 100) * circumference;

  return (
    <svg width={radius * 2} height={radius * 2} viewBox={`0 0 ${radius * 2} ${radius * 2}`}>
      <defs>
        {/* Define the radial gradient for the circle border */}
        <linearGradient id="circleGradient" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" stopColor="rgba(127, 127, 123, 1)" />
          <stop offset="50%" stopColor="rgba(136, 157, 160, 1)" />
          <stop offset="99%" stopColor="rgba(46, 43, 46, 1)" />
        </linearGradient>
      </defs>
      <circle
        cx={radius}
        cy={radius}
        r={radius - 5} // Adjust this to control the thickness of the circle
        fill="none"
        strokeWidth="10" // Adjust this to control the thickness of the circle border
        // Use the radial gradient for the circle border
        stroke="url(#circleGradient)"
        strokeLinecap="round"
        strokeDasharray={strokeDasharray}
        strokeDashoffset={strokeDashoffset}
      />
      <text x="50%" y="50%" textAnchor="middle" alignmentBaseline="middle" fontSize="20" fill="white" fontWeight={600}>
        {percentage}
      </text>
      <text x="50%" y={radius * 2 + 20} textAnchor="middle" alignmentBaseline="middle" fontSize="16" fill={textColor}>
        {title}
      </text>
    </svg>
  );
};

export default GradientCircle;
