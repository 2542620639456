import React from 'react';
import './imagecard.css';
import { Link } from 'react-router-dom';

const ImageCard = ({ imageUrl, title, description,customClassName  }) => {
  return (
   
   
    <div className={`image-card ${customClassName}`}>
    
      <img src={imageUrl} alt={title} className="image" />
      <div className="overlay">
        <h3 className="title">{title}</h3>
         
      </div>
    </div>
  );
};

export default ImageCard;
