import React, { useState, useEffect } from 'react';
import './skills.css';
import GradientCircle from '../GradientCircle';

const Skills = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const windowHeight = window.innerHeight;
      const skillsElement = document.getElementById('skills');
      const skillsElementTop = skillsElement.getBoundingClientRect().top;

      if (skillsElementTop < windowHeight * 0.75) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={`section__padding section__size section__margin ${isVisible ? 'show' : ''}`} id='skills'>
      <div className='app__skills-content'>
        <h1 className='headtext__cormorant'>Skills</h1>
        <p className='p__opensans'>As an IT Project Manager, I adeptly orchestrate timelines and resources to ensure seamless project execution. Leveraging my strategic digital marketing expertise, I craft innovative campaigns that resonate with target audiences, driving meaningful engagement and growth. Additionally, my proficiency in front-end website development empowers me to create visually stunning and user-friendly digital experiences that leave a lasting impression</p>
      </div>
      <div className='app__skills-progress'>
        <div>
          <GradientCircle radius={50} textColor="black" gradient={['#FF0000', '#FFFF00']} />
          <p className='p__opensans'>IT Project Manager</p>
        </div>
        <div>
          <GradientCircle radius={50} textColor="black" gradient={['#FF0000', '#FFFF00']} />
          <p className='p__opensans'>Strategic Digital Marketer</p>
        </div>
        <div>
          <GradientCircle radius={50} textColor="black" gradient={['#FF0000', '#FFFF00']} />
          <p className='p__opensans'>Front End Developer</p>
        </div>
      </div>
    </div>
  );
};

export default Skills;
