import React from 'react'
import './productitem.css'
import grabhub from '../ProjectImages/Grabhub Partner app.png'
const ProductItem = (props) => {
  const redirectToLink = () => {
    window.open(props.url, '_blank');
  };


  return (
    <div className='app__productitem'>
         <h1 className='headtext__cormorant'> {props.title}</h1>
        <img src={props.imageUrl} alt=''/>
        <h1 className='headtext__cormorant'> About this app</h1>
        <p>{props.description}</p> 
        <h1 className='headtext__cormorant'>Tools and Technology</h1>
        <p>{props.technology}</p> 
        <button className='explore_more-button' onClick={redirectToLink}>Redirect to link</button>
     </div>
  )
}

export default ProductItem