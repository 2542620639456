import React from 'react';
import './tab1.css';
import ProjectCarousel from './ProjectCarousel';
import { Link } from 'react-router-dom';

const Tab1 = () => {
  const handleExploreClick = () => {
    // Replace 'https://example.com' with your desired URL
    window.open('https://docs.google.com/document/d/1MJM5Syqegv6DzjViMU8YXzm7bxqozJeS5FCAOVEf4Yg/edit?usp=sharing', '_blank');
  };

  return (
    <div className='app__tab1'>
      <ProjectCarousel />
      <Link to='/projectmanagementprojects'><button className='explore_more-button' >Explore Projects</button></Link>
    </div>
  );
};

export default Tab1;
