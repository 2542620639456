import React from 'react';
import './footer.css';
import { FaTwitter, FaLinkedin, FaGithub ,FaInstagram } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="footer-container section__margin">
      <div className="left">
        <p>Email: usama.malik123@live.com</p>
      </div>
      <div className="right">
       
        <a href="https://www.linkedin.com/in/projectmanager-digitalmarketer-expert/" target="_blank" rel="noopener noreferrer"><FaLinkedin /></a>
        <a href="https://github.com/UsamaMalik123/" target="_blank" rel="noopener noreferrer"><FaGithub /></a>
        <a href="https://www.instagram.com/usamadigitals/" target="_blank" rel="noopener noreferrer"><FaInstagram /></a> {/* Change "your-instagram-username" to your actual Instagram username */}
      </div>
    </footer>
  );
};

export default Footer;
