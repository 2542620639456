// projectsData.js
import Hands from './ProjectImages/HandyHands.png'
import Youdeh from './ProjectImages/youdeh.png'
import IAPply from './ProjectImages/IAPply.png'
 
import EddyShopper from './ProjectImages/Eddy SHopper.png'
import Grabhub from './ProjectImages/Grabhub Partner app.png'
import Barber from './ProjectImages/Barber APp.png'
import Hairplus from './ProjectImages/barber Design.png'
import Albash from './ProjectImages/Albash.png'
import BabaHealth from './ProjectImages/baba health.png'
import anulaSaree from './ProjectImages/Anula Saree.png'
import CodeAuto from './ProjectImages/Code Auto.png'
import Jadi from './ProjectImages/Jaadi.png'
import Absolute from './ProjectImages/Absolutecustomtee.png'
import JPG from './ProjectImages/JPG.png'
import olabs from './ProjectImages/Olabs.png'
import triforyou from './ProjectImages/triforyou.png'
import akamasbuggy from './ProjectImages/akamasbuggy.png'
import econyq from './ProjectImages/econyq.png'
import blueworldcity from './ProjectImages/blueworldcity.png'
import Advice from './ProjectImages/Advice.png'


 

 
const projectsData = [
   
    {
      id: 19,
      name: "Advice", 
      image: Advice,
      technology:"Php Codeignitor",
      url:"https://advice.pk/",
      description:"At Advice.pk, we understand that finding the perfect property can be a daunting task, whether you're searching for your dream home or looking to invest in lucrative real estate opportunities.",
      
    },
    {
      id: 18,
      name: "Econyq", 
      image: econyq,
      technology:"Wordpress.",
      url:"https://econyq.com/news/",
      description:"Welcome to Econyq Chauffeur Services, where sophistication, reliability, and comfort converge to redefine your travel experience. As a leading chauffeur company, we pride ourselves on delivering unparalleled service tailored to meet the unique needs of our discerning clientele.",
      
    },
    {
      id: 16,
      name: "Triforyou", 
      image: triforyou,
      technology:"Wordpress.",
      
      description:"Introducing OLabs Coffee Company, your ultimate destination for artisanal coffee beans, brewing equipment, and accessories, all conveniently accessible through our innovative e-commerce website.",
      
    },
    {
      id: 15,
      name: "Olabs", 
      image: olabs,
      technology:"Wordpress.",
      url:"https://olabs.sa/",
      description:"Introducing OLabs Coffee Company, your ultimate destination for artisanal coffee beans, brewing equipment, and accessories, all conveniently accessible through our innovative e-commerce website.",
      
    },
    {
      id: 14,
      name: "AbsoluteCustomTshirt", 
      image: Absolute,
      technology:"Wordpress.",
      url:"https://absolutecustomtees.com/",
      description:"Welcome to Absolute Custom T-Shirt Printing – your one-stop destination for personalized apparel and custom merchandise, now available at your fingertips through our cutting-edge e-commerce website.",
      
    },
    
    {
      id: 17,
      name: "akamasbuggy", 
      image: akamasbuggy,
      technology:"Wordpress.",
      url:"https://olabs.sa/",
      description:"Akamas Buggy Rentals offers thrilling off-road adventures through the stunning landscapes of Akamas Peninsula in Cyprus. Our website serves as your gateway to excitement, providing easy access to top-of-the-line buggy rentals and unforgettable guided tours.",
      
    },
    {
      id: 13,
      name: "JPGCUSTOMTSHIRTPRINTING", 
      image: JPG,
      technology:"Wordpress.",
      url:"https://jpgcustomtshirtprinting.com/",
      description:"Welcome to JPG Custom T-Shirt Printing – your premier destination for high-quality custom apparel and personalized merchandise, all conveniently accessible through our innovative e-commerce website",
      
    }, 
    {
      id: 19,
      name: "blueworldcity", 
      image: blueworldcity,
      technology:"PHP Codeignitor.",
      url:"https://blueworldcity.pk/",
      description:"Welcome to Econyq Chauffeur Services, where sophistication, reliability, and comfort converge to redefine your travel experience. As a leading chauffeur company, we pride ourselves on delivering unparalleled service tailored to meet the unique needs of our discerning clientele.",
      
    },
    {
      id: 1,
      name: "Youdeh", 
      image: Youdeh,
      technology:"React Native Expo (for Android and iOS) &  PHP CODEIGNITOR admin panel.",
      url:"https://play.google.com/store/apps/details?id=com.youdeh.meet",
      description:"YouDeh is an app that allows people to connect. Somewhere out there in the world, someone is trying to reach out to you, and they will pay for your time. This app will allow people to reach you without compromising your privacy, and they will pay you for your time..",
      
    },
    {
      id: 2,
      name: "Grabhub",      
      image: Grabhub,
      technology:"React Native Expo (for Android and iOS) &  PHP CODEIGNITOR admin panel.",
      url:"https://play.google.com/store/apps/details?id=com.dedevelopers.grabhub",
      description:"Grabhub is an on-demand grocery shopping platform that facilitates the purchase of discounted food items, groceries, and other home essentials with a near expiration date or a dent on the packaging. Our eco-friendly business model allows shoppers save money by presenting them with groceries that are still safe to consume at much affordable rates and save the planet by reducing food waste. The Services present you with a set of one or more Retailer virtual storefronts nearby that offers such goods for in- store pick up."
    },
    {
      id: 3,
      name: "Hands",     
      image: Hands,
      technology:"React Native Expo (for Android and iOS) &  PHP CODEIGNITOR admin panel.",
      url:"https://play.google.com/store/apps/details?id=com.hands.app",
      description:'Are you tired of the endless search for reliable service providers like babysitters or cleaner or tutors? Do you wish there was an easier way to find the right person for the job? Look no further than our app - HandyHands! HandyHands is like having a personal connecting platform for all your service needs. Whether you are a busy parent in need of a trustworthy babysitter for your little ones or a busy person.'
    },
    {
      id: 4,
      name: "EddyShopper", 
      image: EddyShopper,
      technology:"React Native Expo (for Android and iOS) &  PHP CODEIGNITOR admin panel.",
      url:"https://play.google.com/store/apps/details?id=com.eddiesshoper.app",
      description:'Welcome to Eddy Shopper - your ultimate destination for hassle-free online shopping! With Eddy Shopper, discover a world of convenience right at your fingertips. Browse through thousands of products across various categories, including electronics, fashion, home essentials, beauty products, and much more. Find the perfect item to suit your needs and preferences with our intuitive search and filter options'
    },
    {
      id: 5,
      name: "I-Apply",
      technology:"React Native Expo (for Android and iOS) &  PHP CODEIGNITOR admin panel.",
      image: IAPply,
      url:"https://play.google.com/store/apps/details?id=com.dedevelopers.iApply",
      description:'Applying to study abroad can be a confusing process with many different requirements and components. To make your application process simple and faster Student Counselling Services has designed the iApply Application. Note that your data will only be shared with trusted counselors needed to process your application. So only you can access your records'
    },
    
    {
      id: 6,
      name: "BarberApp",
      technology:"React Native Expo (for Android and iOS) &  PHP CODEIGNITOR admin panel.",
      image: Barber,
       
      description:'Looking to book an appointment at Holmes Barber Co? Download our app today to book and manage your appointments.'
    },
    {
      id: 7,
      name: "HairPlus",
      technology:"React Native Expo (for Android and iOS) &  PHP CODEIGNITOR admin panel.",
      image: Hairplus,
       
      description:'This App is meant to connect customers (people) to the nearest barbers’ shop (hairstylist vendors). Customers should able to find the nearest barbers ‘shop in their country or area • Customers and barbers should able to sign-up, register and sign-in to their respective app • Customers should be able to • view various available hairstyles'
    },
    {
      id: 8,
      name: "Baba Health",
      technology:"React Native Expo.",
      image: BabaHealth,
       
      description:'Baba Telehealth provides full-spectrum virtual care. In Nigeria and around the world, there is a gap in connecting patients with doctors directly, particularly for people with limited ability or time constraints. We started by looking at what works and making it easy for patients to connect with doctors. No, do-it- yourself (DIY) with your health, as we help millions of individuals connect with doctors or other healthcare professionals worldwide. Many of the benefits of our advanced telehealth are the same as those of in-person consultations, such as face-to-face communication and consultations.'
    },
    {
      id: 9,
      name: "Anula Saree",
      technology:"✓React Native Expo (webview) ✓php codeignitor",
      image: anulaSaree,
       
      description:'Anula Sarees is a distinguished name in the realm of saree manufacturing, synonymous with unparalleled quality, exquisite designs, and a commitment to craftsmanship. With a passion for preserving the legacy of Indian textiles, we strive to create sarees that capture the essence of tradition while embracing contemporary aesthetics..'
    },
     
    {
      id: 10,
      name: "Code Auto",
      technology:"React Native Expo.",
      image: CodeAuto,
       
      description:'Have you lost your car radio code, pin code or authentication code? Did you buy a radio but the seller did not provide you with the device code? You have disconnected the battery of your vehicle and you need the PIN code or authentication code to unlock your radio? We offer a fast, cheap and secure online unlocking service to obtain your code in less than an hour and 7 days a week for all vehicle brands (Renault, Ford, Audi, Volkswagen, Seat, Opel, Skoda, Nissan , Dacia.'
    },
    {
      id: 11,
      name: "Chicken and Fish",
      technology:"React Native Expo (for Android and iOS) &  PHP CODEIGNITOR admin panel.",
      image: CodeAuto,
       
      description:'Welcome to Chicken&Fish your ultimate destination for deliciously convenient food orders! Whether you are craving succulent chicken dishes or delectable fish delicacies, our app has got you covered..'
    },
     
    {
      id: 12,
      name: "Jaadi App",
      technology:"React Native Expo (for Android and iOS) &  PHP CODEIGNITOR admin panel.",
      image: Jadi,
       
      description:'JAADI is a Q&A platform that empowers people to share and grow the worlds knowledge. People come to JAADI to ask questions about any subject, read high quality knowledge thats personalized and relevant to them, and share their own knowledge with others. JAADI is a place to share knowledge and better understand the world.'
    },
     
   
    // Add more projects as needed
  ];
  
   
  
  export default projectsData;
  