import React, { useState, useEffect } from 'react';
import './home.css';
import HeroImg from '../Assets/Muhammad Usama Strategic Digital Marketer IT Project Manager.png';

const Home = () => {
  const [typedText, setTypedText] = useState('');
  const fullText = "Hi! I'm Muhammad Usama";

  useEffect(() => {
    let currentIndex = 0;
    let typingInterval;
    let timeout;

    const startTyping = () => {
      typingInterval = setInterval(() => {
        setTypedText(fullText.substring(0, currentIndex + 1));
        currentIndex++;
        if (currentIndex === fullText.length) {
          clearInterval(typingInterval);
          timeout = setTimeout(() => {
            setTypedText('');
            currentIndex = 0;
            startTyping(); // Restart typing after 3 seconds
          }, 3000); // 3 seconds delay before clearing the text and restarting
        }
      }, 100); // Typing speed: milliseconds per character
    };

    startTyping(); // Initial start of typing

    return () => {
      clearInterval(typingInterval);
      clearTimeout(timeout);
    };
  }, [fullText]);

  return (
    <div className='app__home section__padding' id='home'>
      <div className='app__home-leftside'>
        <div className='app__home-leftside-welcom_div'>
          <p>Welcome to my portfolio</p>
        </div>
        <h1 className='headtext__cormorant heading'>{typedText}</h1>
        <p className='p__opensans'>Versatile professional proficient in IT project management, strategic digital marketing, and front-end React.js development, adept at orchestrating complex projects, crafting data-driven marketing strategies, and creating visually appealing web interfaces for seamless user experiences.</p>
      </div>
      <div className='app__home-rightside'>
        <div className="image-frame">
          <img src={HeroImg} alt='Usama Malik'/>
        </div>
      </div>
    </div>
  );
};

export default Home;
