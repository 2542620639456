import React from 'react'
import logo from './logo.svg';
import './App.css';
import NavBaar from './Components/NavComponent/NavBaar'; 
 
import Home from './Components/Home/Home';
import Skills from './Components/Skills/Skills';
import Projects from './Components/Projects/Projects';
import ProjectCarousel from './Components/TabComponents/Tab1/ProjectCarousel';
import GetInTouch from './Components/GetInTouch/GetInTouch';
import PersonalAchievement from './Components/PersonalAchievement/PersonalAchievement';
import Technologies from './Components/Technology/TechnologiesSection.jsx';
import Education from './Components/Education/Education.jsx';
import Footer from './Components/Footer/Footer.jsx';
 
import { Helmet } from 'react-helmet';
import Testimonials from './Components/Testimonials/Testimonials.jsx';
 
const Mainpage = () => {
  return (
    <div className="App">
        
        <NavBaar/>
        <Home/>
        <Skills/>
        <Technologies/>
        <Education/>
        <PersonalAchievement/>
        <Projects/>
        <Testimonials/>
        
        
        {/* <GetInTouch/> */}
        <Footer/>
    </div>
  )
}

export default Mainpage