import React from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { MdOutlineRestaurantMenu } from 'react-icons/md';
import logo from '../Assets/logo.png';
import './navbar.css';
import linkedin from '../Assets/linkedin.png'
import { FaTwitter, FaLinkedin, FaGithub, FaInstagram } from 'react-icons/fa';

const Navbaar = () => {
  const [toggleMenu, setToggleMenu] = React.useState(false);
  const openLinkedInProfile = () => {
    window.open('https://www.linkedin.com/in/projectmanager-digitalmarketer-expert/', '_blank');
  };

  return (
    <nav className="app__navbar">
      <div className="app__navbar-logo">
        <img src={logo} alt="app__logo" />
      </div>
      <ul className="app__navbar-links">
        <li className="p__opensans"><a href="#home">Home</a></li>
        <li className="p__opensans"><a href="#skills">Skills</a></li>
        <li className="p__opensans"><a href="#history">History</a></li>
        <li className="p__opensans"><a href="#project">Portfolio</a></li>
        <li className="p__opensans"><a href="#Testimonials">Testimonials</a></li>
        
      </ul>
      <div className="app__navbar-login">
        <div className="right">
          <a href="https://www.linkedin.com/in/projectmanager-digitalmarketer-expert/" target="_blank" rel="noopener noreferrer"><FaLinkedin /></a>
          <a href="https://github.com/UsamaMalik123/" target="_blank" rel="noopener noreferrer"><FaGithub /></a>
          <a href="https://www.instagram.com/usamadigitals/" target="_blank" rel="noopener noreferrer"><FaInstagram /></a> {/* Change "your-instagram-username" to your actual Instagram username */}
        </div>
        {/* <button className='custom-button'>Lets Connect</button> */}
      </div>

      <div className="app__navbar-smallscreen">
        <GiHamburgerMenu color="#fff" fontSize={27} onClick={() => setToggleMenu(true)} />
        {toggleMenu && (
          <div className="app__navbar-smallscreen_overlay flex__center slide-bottom">
            <MdOutlineRestaurantMenu fontSize={27} className="overlay__close" onClick={() => setToggleMenu(false)} />
            <ul className="app__navbar-smallscreen_links">
              <li><a href="#home" onClick={() => setToggleMenu(false)}>Home</a></li>
              <li><a href="#skills" onClick={() => setToggleMenu(false)}>skills</a></li>
              <li><a href="#project" onClick={() => setToggleMenu(false)}>Portfolio</a></li>
              <li><a href="#history" onClick={() => setToggleMenu(false)}>History</a></li>            
              <li><a href="#history" onClick={() => setToggleMenu(false)}>Testimonials</a></li>
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbaar;
