import React from 'react'
import './productpage.css'
import ProductItem from '../ProductItem/ProductItem'
import websitedata from '../websiteDat';
const ProductPagewebsites = () => {
  return (
    <div gradient__bg className='app__productpage'>       
         <h1 className='headtext__cormorant'>React Website portfolio</h1>
        {websitedata.map((project) => (
         <div key={project.id}>
            {/* <a href={project.url} target="_blank" rel="noopener noreferrer"> */}
              <div className="slide-content">
                <ProductItem
                  imageUrl={project.image}
                  title={project.name}
                  description={project.description}
                  url={project.url}
                  technology={project.technology}
                />
                 
              </div>
            {/* </a> */}
          </div>
        ))}
    </div>
  )
}


export default ProductPagewebsites