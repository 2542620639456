import React, { useState, useEffect } from 'react';
import Tab1 from '../TabComponents/Tab1/Tab1';
import Tab2 from '../TabComponents/Tab2/Tab2';
import Tab3 from '../TabComponents/Tab3/Tab3';
import './project.css';

const Projects = () => {
  const [activeTab, setActiveTab] = useState(1);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const windowHeight = window.innerHeight;
      const projectsElement = document.getElementById('project');
      const projectsElementTop = projectsElement.getBoundingClientRect().top;

      if (projectsElementTop < windowHeight * 0.75) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  return (
    <div className={`app__projects ${isVisible ? 'show' : ''}`} id='project'>
      <div className='app__projects-content'>
        <h1 className='headtext__cormorant'> Projects </h1>
        <p className='p__opensans'>In my portfolio, each pixel tells a story of meticulous design and strategic vision, showcasing a blend of artistry and expertise that captivates audiences and drives meaningful engagement</p>
      </div>
      <div className="tabs-container">
        <button
          className={`tab-button ${activeTab === 1 ? 'active-tab' : ''}`}
          onClick={() => handleTabClick(1)}
          style={{ borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px' }}
        >
         Project Management
        </button>
        <button
          className={`tab-button ${activeTab === 2 ? 'active-tab' : ''}`}
          onClick={() => handleTabClick(2)}
        >
          Front End Development
        </button>
        <button
          className={`tab-button ${activeTab === 3 ? 'active-tab' : ''}`}
          onClick={() => handleTabClick(3)}
          style={{ borderTopRightRadius: '5px', borderBottomRightRadius: '5px' }}
        >
          Digital Marketing
        </button>
      </div>

      <div className="tab-content">
        {activeTab === 1 && <Tab1 />}
        {activeTab === 2 && <Tab2 />}
        {activeTab === 3 && <Tab3 />}
       </div>
    </div>
  );
}

export default Projects;
