import React, { useState } from 'react';
import './testimonialssubpage.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ImageCard from '../../Components/TabComponents/Tab1/ImageCard';
import Reviews from '../Reviews';
import '../../Components/TabComponents/Tab1/projectcarousel.css';

const TestimonialsSubPage = () => {
  const [zoomedImage, setZoomedImage] = useState(null);

  const handleImageClick = (imageUrl) => {
    setZoomedImage(imageUrl);
  };

  const handleCloseZoomedImage = () => {
    setZoomedImage(null);
  };

  return (

    <div className='app__testimonials-subpage'> 
        <div className='gradient__bg  'style={{height:'50px',width:'100%',display:'flex',justifyContent:'center',alignItems:'center',color:'white',fontSize: '36px',fontWeight:700}}>Testimonials</div>
         <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <div className="project-container">
              {Reviews.map((project) => (
                <div key={project.id} onClick={() => handleImageClick(project.image)}>
                  <div className="slide-content">
                    <ImageCard
                      imageUrl={project.image}
                      title={project.name}
                      description={project.description}
                      customClassName="testimonial-image-card"
                    />
                  </div>
                </div>
              ))}
            </div>
            {zoomedImage && (
              <div className="zoomed-image-overlay" onClick={handleCloseZoomedImage}>
                <img src={zoomedImage} alt="Zoomed" />
              </div>
            )}
          </div>
    </div>
    
  );
};

export default TestimonialsSubPage;
