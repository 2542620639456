import React, { useState, useEffect } from 'react';
import './technologies.css';

const Technologies = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const windowHeight = window.innerHeight;
      const technologiesElement = document.getElementById('history');
      const technologiesElementTop = technologiesElement.getBoundingClientRect().top;

      if (technologiesElementTop < windowHeight * 0.75) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={`technologies-container section__padding ${isVisible ? 'show' : ''}`} id='history'>
      <h1 className="technologies-heading headtext__cormorant ">Work History</h1>
      <div className="gradient-line"></div>
      <div className="box-container">
        <div className="box">
          <h2 className="box-heading">2018</h2>
          <p className="box-description">Graduated As Software Engineer and started android app development in Ranksol</p>
        </div>
        <div className="box">
          <h2 className="box-heading">2019</h2>
          <p className="box-description">Worked As a freelancer and as a part time android developer in Software house </p>
        </div>
        <div className="box">
          <h2 className="box-heading">2019-2023</h2>
          <p className="box-description">IT Project Manager at Fubtechx</p>
        </div>
        <div className="box">
          <h2 className="box-heading">2023</h2>
          <p className="box-description">Social Media Manager for Ecommerce Stores</p>
        </div>
        <div className="box">
          <h2 className="box-heading">2023</h2>
          <p className="box-description">React Js Front End Development</p>
        </div>
      </div>
    </div>
  );
};

export default Technologies;
