// websiteData.js
import HooBank from './Assets/Hoobank.png';
import Resturaunt from './Assets/Resturaunt.png';
import Ecommerce from './Assets/React Ecommerce.png';
import AI from './Assets/AI Landing page.png';
import Gym from './Assets/Gym.png';

const websiteData = [
  {
    id: 1,
    name: "HooBank", 
    image: HooBank,
    description:'Welcome to Hoo Bank where banking meets innovation, convenience, and reliability, all elegantly crafted with React.js! Step into the future of banking with our meticulously designed landing page, offering a comprehensive overview of our services, features, and commitment to excellence.',
    url:"https://hoobank-nu-eight.vercel.app/",
  
  },
  {
    id: 2,
    name: "Resturaunt",      
    image: Resturaunt,
    description:'Welcome to our Restaurant Landing Page – your gateway to an exquisite culinary experience, elegantly presented through the power of React.js! Immerse yourself in a world of tantalizing flavors, impeccable service, and inviting ambiance, all beautifully showcased on our meticulously crafted landing page',
    url:"https://resturaunt-page-elhpgpc63-usamamalik123s-projects.vercel.app/#awards"
  },
  {
    id: 3,
    name: "AI",     
    image: AI,
    description:'Welcome to AI Description where cutting-edge technology meets seamless user experience, all powered by React.js! Our landing page showcases the incredible capabilities of artificial intelligence in generating captivating and accurate descriptions for any purpose',
    url:" https://open-ai-front-end.vercel.app/"
  },
  {
    id: 4,
    name: "Ecommerce", 
    image: Ecommerce,
    technology:"React Js.",
    description:'Introducing Exommerce  your premier destination for cutting-edge e-commerce solutions built with React.js! Our front-end website offers an unparalleled user experience, seamlessly blending aesthetics with functionality to create a truly captivating online shopping environment',
    url:"https://ecommerce-static-site-79mkak6rq-usamamalik123s-projects.vercel.app/"
  },
  {
    id: 5,
    name: "Gym App", 
    image: Gym,
    technology:"React Js.",
    description:'Welcome to our Gym Landing Page – your ultimate destination for fitness, wellness, and transformation, beautifully brought to life with React.js! Step into a world of health and vitality as you explore our dynamic landing page, designed to inspire and empower you on your fitness journey',
    url:"https://ecommerce-static-site-79mkak6rq-usamamalik123s-projects.vercel.app/"
  },
  // Add more projects as needed
];

export default websiteData; // Exporting websiteData
